import React from "react"
import {
  Container,
  Layout,
  SEO,
  Grid,
  TextRow,
  ImageRow,
  ExternalLink,
  Tag,
} from "../../components"

import example from "../../images/projects/livecam.png"

const Livecam = () => (
  <Layout hideProfile>
    <SEO title="LiveCam: Live Streaming" />
    <Container>
      <Grid>
        <TextRow>
          <h1>LiveCam: Live Streaming</h1>
          <Tag>AngularJS</Tag>
          <Tag>Node</Tag>
          <Tag>Web Socket</Tag>
          <Tag>Nginx</Tag>
          <Tag>Saas</Tag>
          <Tag>Babel</Tag>
          <Tag>Gulp</Tag>

          <p>
            <strong>Sexlog</strong> is the largest social network adult content
            in Brazil. For being a social network, we had a lot of challenges
            related to usability and feature to connect our users.
          </p>
          <p>
            The LiveCam project was created based on the strategy to keep our
            users inside of the platform allowing them to have a security
            instantly exhibition.
          </p>

          <p>
            The LiveCam is a tool that allows users to broadcast live and
            interact. Through the chat (made using WebSocket) it's possible to
            send a message and interact with everyone in the room. Besides that
            is possible to send a gift for the exhibitionist using the virtual
            coin made inside of this platform. The main front-end technology
            used was <strong>AngularJS</strong>. The style was written using{" "}
            <strong>SAAS</strong> and new feature of <strong>CSS3</strong>. To
            keep the <strong>real-time</strong> communication we used the{" "}
            <strong>WebSocket API</strong>.
          </p>
          <p>
            I performed as one of <strong>Front-end engineers</strong>.
          </p>
          <p>
            Click on the link below and create an account to see the result.
          </p>

          <ExternalLink
            href="https://sexlog.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sexlog
          </ExternalLink>
        </TextRow>

        <ImageRow>
          <img alt="LiveCam example" src={example} />
        </ImageRow>
      </Grid>
    </Container>
  </Layout>
)

export default Livecam
